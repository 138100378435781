export default {
  "buttonProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "buttonContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "designerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ux Ui Designer"])},
  "devText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer."])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can help you in bringing your projects to life through the creation or redesign of user-friendly and visually appealing web interfaces."])},
  "projectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "filtertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "aboutP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi I'm Betsabé, my work combines user experience, visual design, and frontend development."])},
  "aboutP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After working as an architect for several years, I've shifted my focus to creating digital products. I'm passionate about web design, user-centric experiences and creating products that improve people's lives."])},
  "aboutP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My background in architecture has given me a great sensitivity towards visual details, and a great curiosity for the technical aspect, so another area that interests me is frontend development."])},
  "aboutP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm currently working as a freelance, and I'm looking for new professional opportunities or a permanent contract. If you want to work with me do not hesitate to contact me."])},
  "happy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design thinking as a tool to solve today's problems"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HappyLife is a project that aims to find a solution to the problem of social isolation, with a design thinking approach."])}
  },
  "figy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design of a marketplace app for the sale of phygital and ecological products"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIGY is a mobile app, designed as a final project for the Master's Degree in User Experience by Elisava, in Spain. It's a disruptive platform that offers sustainable products associated with an NFT, guaranteeing their traceability."])}
  },
  "wand": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design of a domotic application for the control of the connected devices of the house."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project consisted of research, architecture, UX writing, and design of a smart home app for the Master's Degree in Experience Design by ELISAVA."])}
  },
  "groupomania": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a corporate social network to improve employee satisfaction."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project completed during the web developer training program at Openclassrooms, in France. The Groupomania group's internal social network, allows to create a user account, connect and participate in the company's internal forum."])}
  },
  "hot": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of an API for a hot sauce web application"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of an API for a web application where users can recommend their favorite hot sauces and rate other users' choices.."])}
  },
  "csm": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web integration challenge, created by the frontend practice website."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web integration challenge, created by the frontend practice website. The exercise consisted of recreating the homepage of Canal Street Market, taking into account the graphic style, colors, typography, animations, etc. The mockup is also responsive."])}
  },
  "cv": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom theme creation on Wordpress."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog created with wordpress. The project consists of the creation and integration of a custom theme for wordpress, the creation of a database, and its online publishing."])}
  },
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download cv"])},
  "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch!"])}
}